import { $axios } from '../config/axios'
import {
  RegAchievement,
  ResAchievementClass,
  RegAchievementDetail,
  ResAchievementDetailClass,
} from '@/model/industryManagement/achievement'
import Data from '@/model/common/Data'
class AchievementApi {
  //成果信息页面分页查询。
  ListDemandAchievement(data: RegAchievement): Promise<ResAchievementClass> {
    return $axios.post('/api/demand/listDemandAchievement', data)
  }
  //成果详情信息页面分页查询。
  ListDemandAchievementDetail(data: unknown): Promise<ResAchievementDetailClass> {
    return $axios.post('/api/demand/listDemandAchievementDetail', data)
  }
  //查询行业接口
  queryOptionalType(data: unknown): Promise<Data[]> {
    return $axios.post('/api/optional/type/query', data)
  }
}
let achievementApi
export default (() => {
  if (achievementApi) return achievementApi
  achievementApi = new AchievementApi()
  return achievementApi
})()
